import { useEffect, useRef, useState } from 'react';

import { useRouter } from 'next/router';
import { useDebounce, useKey } from 'react-use';
import useSWR from 'swr';

import { ParsedProductsApiResponse } from '@hultafors/shared/types';

import { PAGE_SIZE_S, routes } from '@hultafors/solidgear/helpers';
import { useGlobal } from '@hultafors/solidgear/hooks';
import { SolidgearProduct } from '@hultafors/solidgear/types';

import { Grid } from '../grid/grid';
import { H3 } from '../H3/H3';
import { Loader } from '../loader/loader';
import { Products } from '../products/products';
import { TextButton } from '../text-button/text-button';

import {
  ButtonContainer,
  Result,
  SearchBoxStyled,
  SearchInputStyled,
} from './SearchBox.styled';

export interface SearchBoxProps {
  isAutoComplete?: boolean;
  isOpen?: boolean;
  placeholder?: string;
  toggleSearchBox?: (type: string) => void;
  passive?: boolean;
  inMobileMenu?: boolean;
  initialValue?: string;
  className?: string;
  toggleResults?: (force?: boolean) => void;
}

export const SearchBox: React.FC<SearchBoxProps> = ({
  isOpen,
  toggleSearchBox,
  placeholder,
  passive,
  initialValue = '',
  className,
  toggleResults,
}) => {
  const { global, settings } = useGlobal();
  const [term, setTerm] = useState(initialValue);
  const [searchValue, setSearchValue] = useState(initialValue);
  const inputRef = useRef<HTMLInputElement>(null);

  const [, cancel] = useDebounce(
    () => {
      setSearchValue(term);
    },
    300,
    [term],
  );

  const { locale, ...router } = useRouter();

  const { data, isLoading } = useSWR<
    ParsedProductsApiResponse<SolidgearProduct>
  >(
    !passive
    && searchValue
    && settings.pt?.searchSection
    && `/api/searchlist/${settings.pt.searchSection}?${new URLSearchParams({
        searchValue,
        pageSize: `${PAGE_SIZE_S}`,
        includeFilters: 'false',
        ...(locale && locale !== 'com' ? { locale } : {}),
      }).toString()}`,
    {
      revalidateIfStale: true,
      revalidateOnMount: true,
    },
  );

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef?.current?.focus();
    }
  }, [isOpen]);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setTerm(event.target.value);
  };

  const onSubmit: React.FormEventHandler = (event) => {
    event.preventDefault();
    if (term.length) {
      cancel();
      const query = new URLSearchParams({ q: term }).toString();
      router.push(`/${routes.SEARCH}?${query}`);

      clearSearch();
      toggleSearchBox?.('search');
    }
  };

  const clearSearch = () => {
    setTerm('');
    setSearchValue('');
    cancel();
  };

  useKey('Escape', clearSearch);

  useEffect(() => {
    if (!isOpen) {
      clearSearch();
    }
  }, [isOpen]);

  useEffect(() => {
    toggleResults?.(!isLoading && !!data?.items?.length);
  }, [isLoading, data, toggleResults]);

  return (
    <SearchBoxStyled className={className}>
      <SearchInputStyled
        onSubmit={onSubmit}
        onChange={onChange}
        onClear={clearSearch}
        placeholder={placeholder || global?.searchLabel || ''}
        value={term}
      />

      <Result $show>
        {isLoading && (
          <div>
            <Loader />
          </div>
        )}
        {!isLoading && !!data?.items?.length && (
          <>
            <H3 contentType>{global.suggestions}</H3>
            <ButtonContainer>
              <TextButton
                onClick={onSubmit}
                iconUrl="/assets/gfx/arrow-right.svg"
                style={{ width: '16px', height: '16px' }}
              >
                {global.viewAll}
              </TextButton>
            </ButtonContainer>
            <Grid
              className="grid"
              columnGap={[{ columnGap: 16 }]}
              columns={[{ columns: 1 }, { breakpoint: 'desktop', columns: 3 }]}
            >
              <Products
                products={data.items}
                rrpLabel={global.rrpLabel || ''}
                rrpIncludingVatLabel={global.rrpIncludingVatLabel || ''}
                toggleSearchBox={toggleSearchBox}
              />
            </Grid>
            <ButtonContainer>
              <TextButton
                onClick={clearSearch}
                iconLeft={true}
                iconUrl="/assets/gfx/close.svg"
                style={{ width: '16px', height: '16px' }}
              >
                {global.clear}
              </TextButton>
              <TextButton
                onClick={onSubmit}
                iconUrl="/assets/gfx/arrow-right.svg"
                style={{ width: '16px', height: '16px' }}
              >
                {global.viewAll}
              </TextButton>
            </ButtonContainer>
          </>
        )}
      </Result>
    </SearchBoxStyled>
  );
};
